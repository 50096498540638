<template>
		<Dialog header="Header" v-model:visible="showDlg" :style="{width: '50vw'}" :modal="true" @hide="hideDlg" :closable="true" :closeOnEscape="true">
			<template #header>				
				Buscar Cuentahabiente
			</template>
				<div class="p-fluid p-field">
					<div class="p-inputgroup">
						<InputText id="filtroCtaHabiente" type="text" placeholder="Escriba el criterio de búsqueda" v-model="filtroBusquedaCtaHabiente"	@keyup.enter="buscarCtaHabiente" autofocus autocomplete="false"/>
						<Button icon="pi pi-search" @click="buscarCtaHabiente"/>
					</div>
				</div>				
				<div class="p-fluid p-field">
					<div v-if="lkpCtaHabientes.length > 0">
						<DataTable id="resultados" :value="lkpCtaHabientes" :scrollable="true" scrollHeight="flex" :loading="ctaHabienteDialogLoading"
						v-model:selection="selCtaHabiente" selectionMode="single" dataKey="id_cta_habiente_info" @row-select="seleccionarCtaHabiente">
						<Column field="nombre_comercial" header="Nombre Comercial" style="min-width:200px"></Column>
						<Column field="nit" header="NIT" style="min-width:200px"></Column>
						<Column field="telefono" header="Teléfono" style="min-width:200px"></Column>
						</DataTable>				
					</div>
					<div v-else>
						<h5 style="margin: 15px auto; text-align: center;">No hay datos para mostrar</h5>
					</div>
				</div>			
			<template #footer>
				<Button label="Cancelar" icon="pi pi-times" @click="cancelarBuscarCtaHabiente" class="p-button-text"/>
				<Button v-if="ctaHabienteSelected" label="Seleccionar" icon="pi pi-check" @click="elegirCtaHabiente" autofocus />
			</template>
		</Dialog>
</template>

<script>
	import grlService from '../service/grl.service';
import GrlService from '../service/grl.service';
	import InvService from '../service/inv.service';

	export default {
		name: 'BuscarCtaHabiente',
		props: {
			mostrar: {type: Boolean, required: true}
		},
		data() {
				return {
					selCtaHabienteInfo: this.IdCtaHabienteInfo,
					selCtaHabiente: null,
					showDlg: this.mostrar,
					ctaHabienteDialog: null,				
					ctaHabienteDialogLoading: false,
					filtroBusquedaCtaHabiente: null,
					lkpCtaHabientes: []
				}
		},
		computed: {
			ctaHabienteSelected() {
				return this.selCtaHabiente;
			}		
		},
		methods: {
			hideDlg() {
				this.cancelarBuscarCtaHabiente();
			},
			cancelarBuscarCtaHabiente() {
				this.showDlg = false;					
				this.$emit('cancelar');				
			},
			seleccionarCtaHabiente(){
				if(this.lkpCtaHabientes.length == 1){
					this.elegirCtaHabiente();		
				}
			},
			async elegirCtaHabiente() {
				this.showDlg = false;			
				try {
					this.selCtaHabiente.emails = await InvService.getCtaHabienteEmailsNotificacion(this.$store.state.auth.currentAppCtx, this.selCtaHabiente.id_cta_habiente_info);					
					var condicionesPago = await grlService.getCtaHabienteCondicionesPago(this.$store.state.auth.currentAppCtx, this.selCtaHabiente.id_cta_habiente_info);

					if (condicionesPago.length == 0) {
						this.selCtaHabiente.condicionesPago = this.$store.state.auth.currentAppCtx.CondicionesPago;
					} else {
						this.selCtaHabiente.condicionesPago = condicionesPago;						
					}

					this.$emit('seleccionar', this.selCtaHabiente);		
					this.selCtaHabiente = null;
				} catch (error) {
						this.$toast.add({severity:'error', summary: 'Error', detail: error, life: 3000})
				}
			},
			buscarCtaHabiente() {
				this.ctaHabienteDialogLoading = true;
				this.selCtaHabiente = null;
				GrlService.getBuscaCtaHabiente(this.$store.state.auth.currentAppCtx, "0", this.filtroBusquedaCtaHabiente).then(data=>{
					this.lkpCtaHabientes = data;
					this.selCtaHabiente = null;
					this.ctaHabienteDialogLoading = false;
				}).catch(data => {
					this.ctaHabienteDialogLoading = false;
					this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
				}
				);
			}
		}
	}
</script>

<style scoped>

</style>
